
.lg_box {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../../imgs/login-bg.svg') no-repeat;
  background-size: cover;
  .lg_card {
    width: 340px;
    text-align: center;
    margin: 10% auto;
  }
}
